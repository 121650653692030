import { createRoot } from 'react-dom/client';

import './style.scss';
import Style from './Components/Common/Style';
import Carousel from './Components/Common/Carousel';

document.addEventListener('DOMContentLoaded', () => {
	const carouselEls = document.querySelectorAll('.wp-block-bicb-carousel');
	carouselEls.forEach(carouselEl => {
		const attributes = JSON.parse(carouselEl.dataset.attributes);
		const { cId } = attributes;

		createRoot(carouselEl).render(<>
			<Style attributes={attributes} clientId={cId} />

			<Carousel attributes={attributes} />
		</>);

		carouselEl?.removeAttribute('data-attributes');
	});
});