import { getBackgroundCSS, getSpaceCSS } from '../../../../Components/utils/getCSS';

const Style = ({ attributes, clientId }) => {
	const { height = '250px', background, padding, pageSize, pageColor, prevNextSize, prevNextColor } = attributes;

	const mainSl = `#bicbCarousel-${clientId}`;
	const carouselSl = `${mainSl} .bicbCarousel`;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			${mainSl}{
				${getBackgroundCSS(background)}
				padding: ${getSpaceCSS(padding)};
			}
			${carouselSl} .carousel{
				height: ${height};
			}
			${carouselSl} .swiper-pagination .swiper-pagination-bullet{
				background: ${pageColor};
				width: ${pageSize};
				height: ${pageSize};
			}
			${carouselSl} .swiper-button-prev,
			${carouselSl} .swiper-button-next{
				color: ${prevNextColor};
			}
			${carouselSl} .swiper-button-prev::after,
			${carouselSl} .swiper-button-next::after {
				font-size: ${prevNextSize};
			}
			`.replace(/\s+/g, ' ')
		}} />
	</>
}
export default Style;