import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Carousel = ({ attributes, initialSlide = 0, isBackend = false }) => {
	const { carousels, carouselOptions, isPage, isPrevNext } = attributes;
	const { columns, columnGap, isLoop, speed, autoplayOptions } = carouselOptions;
	const { enabled: isAutoplay, delay = 1500 } = autoplayOptions

	return <Swiper
		className='bicbCarousel'
		direction='horizontal'
		initialSlide={initialSlide}
		slidesPerView={columns.mobile}
		breakpoints={{ 576: { slidesPerView: columns.tablet }, 768: { slidesPerView: columns.desktop } }}
		spaceBetween={columnGap}
		modules={[Autoplay, Navigation, Pagination]}
		loop={isLoop}
		allowTouchMove={isBackend ? false : true}
		grabCursor={isBackend ? false : true}
		autoplay={isAutoplay && !isBackend ? { delay: delay * 1000 } : false}
		speed={speed * 1000}
		effect='slide'
		pagination={isPage ? {
			clickable: true,
			dynamicBullets: false,
			forceClass: true
		} : false}
		navigation={isPrevNext}
		allowSlidePrev={true}
		allowSlideNext={true}
		autoHeight={false}
		notificationClass={null}
	>
		{carousels?.map((carousel, index) => {
			const { image } = carousel;

			return <SwiperSlide key={index}>
				<figure className={`carousel carousel-${index}`}>
					{image?.url && <img src={image?.url} alt={image?.alt || image?.title} />}
				</figure>
			</SwiperSlide>
		})}
	</Swiper>
}
export default Carousel;